<template>
  <div class="">
    <!-- <a href="https://www.baidu.com" target="_blank" @click="outerLink">baidu</a>
      <button @click="$i18n.locale == 'en' ? $i18n.locale = 'zh' : $i18n.locale = 'en'">切换语言</button> -->
    <!-- <button @click="downFile">下载</button> -->
    <NavigationBar :ruleForm="headForm" :courseForm="courseForm" :enabledProgress="enabledProgress"></NavigationBar>
    <TopHead :detail="detail" :ruleForm="headForm" :enabledProgress="enabledProgress"></TopHead>
    <ToTheme v-if="$route.query && ($route.query.pageType == 'index' || $route.query.pageType == 'menu')"  
      :ruleForm="headForm" :enabledProgress="enabledProgress"></ToTheme>
    <TopBody v-if="$route.query && $route.query.pageType == 'content'" 
      @loaded="()=>$emit('loaded')" 
      @scrollToModule="(args)=>$emit('scrollToModule',args)"
      @scrollToNew="(args)=>$emit('scrollToNew',args)" 
      :enabledProgress="enabledProgress"
      :containerStyle="containerStyle"></TopBody>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import NavigationBar from "@/renderer/components/scaffold/navigationBar.vue";
import TopHead from "@/renderer/components/scaffold/tophead.vue";
import TopBody from "@/renderer/components/scaffold/tobody.vue";

import ToTheme from "./totheme.vue";
// const { ipcRenderer } = window.require('electron'); //浏览器端报错
export default {
  props: {
    detail: Object,
    children: Array,
    enabledProgress: Boolean,
    containerStyle: Object,
  },
  components: {
    TopHead,
    TopBody,
    ToTheme,
    NavigationBar
  },
  data() {
    return {
      drawerSet: {
        direction: "rtl", //从右往左
        visible: false, //是否显示
        title: "",
        activeName: 0,
      },
      ruleForm: {
        'contentobject-title':"标题",
        'contentobject-displayTitle': "<p><span style='color:orange'>标题1</span></p><p><span style='color:orange'>标题1</span></p><p><span style='color:orange'>标题1</span></p>",
        'contentobject-subtitle':"副标题",
        'contentobject-body': "内容区域",
        'contentobject-pageBody': ""
      },
    };
  },
  computed: {
    ...mapState({
      showPop: (state) => state.showPop,
      popDetail: (state) => state.popDetail,
      courseDefault: state => state.course.defaultVal && state.course.defaultVal.course || {},
      contentForm: state => state.content.contentForm,
    }),
    ...mapGetters([
      'getCourseFormById'
    ]),
    courseForm() {
      return this.getCourseFormById(this.$route.params.id) || {};
    },
    tabList() {
      return (this.popDetail && this.popDetail.tabgroup_list) || [];
    },
    headForm(){
      return this.$route.query && (this.$route.query.pageType == 'index') ? JSON.parse( JSON.stringify(this.courseForm) ) : this.contentForm;
    }
  },
  watch: {
  },
  mounted() {
    // ipcRenderer.send('fromFullPage'); //往主进程发送消息 -使用方法一
    this.$electron && this.$electron.ipcRenderer.send("fromFullPage"); //往主进程发送消息 -使用方法二, 基于main.js 挂载了 electron
  },
  beforeDestroy() {
  },
  methods: {
    outerLink(event) {
      this.$electron && event.preventDefault(); //浏览器 不阻止
      this.$electron &&
        this.$electron.shell.openExternal("https://www.baidu.com"); //electron 打开默认浏览器访问
    },
    downFile() {
      // let downLoadUrl = 'https://builder.fei-dao.com/download/615041cbb0b3b518f8369289/61663ca685c24474ab2211cb/444/download.zip';
      // let downLoadUrl = 'https://mp32.9ku.com/upload/128/2020/04/17/1003659.mp3';
      // let downLoadUrl = 'https://jkxuetang-master.oss-cn-shenzhen.aliyuncs.com/tmp/msg.png';
      let downLoadUrl =
        "https://jkxuetang-master.oss-cn-shenzhen.aliyuncs.com/tmp/test001%20(1).zip";
      // 桌面端
      this.desktopDownLoad(downLoadUrl);
      // 浏览器
      if (!this.$electron) {
        this.webDownLoad(downLoadUrl);
      }
    },
    webDownLoad(downLoadUrl) {
      window.location.href = downLoadUrl;
    },
    desktopDownLoad(downLoadUrl) {
      this.$electron &&
        this.$electron.remote &&
        this.$electron.remote.dialog
          .showOpenDialog(null, {
            // 默认文件夹
            // defaultPath :'../Downloads',
            //选择操作，此处是打开文件夹
            properties: ["openDirectory"],
            filters: [{ name: "All", extensions: ["*"] }],
          })
          .then((result) => {
            //取消了选择
            if (result.canceled) {
              return;
            }
            this.$electron &&
              this.$electron.ipcRenderer.send("downLoadFile", {
                downLoadUrl: downLoadUrl,
                targetUrl: result.filePaths[0],
              });
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-tabs__item {
  padding: 0;
}
// 右侧侧边弹窗
/deep/.el-drawer.rtl {
  // width: 479px !important;
}
/deep/.el-drawer__close-btn .el-icon-close {
  font-weight: bold !important;
}
/deep/.el-tabs__nav-wrap::after {
  height: 1px;
  background-color: #e4e4e4;
}
/deep/.el-drawer__body {
  overflow: hidden;
  height: calc(100% - 97px);
}
/deep/.el-tabs,
/deep/.el-tab-pane {
  height: 100%;
}
/deep/.el-tabs__header {
  margin-bottom: 0;
}
/deep/.el-tabs__content {
  height: calc(100% - 40px);
}
</style>
