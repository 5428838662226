<template>
  <topTools class="course-article lineheight_1d5" :class="{'hasValue':leastOneCk, 'not-highlight':notHighlight || blockActive  }" type="center"
    :id="detail['article-_id']"
    :eyeShow="!detail['article-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'add':true,'hideStatus':true, 'moveUp': moveUp, 'moveDown': moveDown}" 
    rightTips="章节" 
    :style="courseHeaderStyle"
    :isHighlight="isHighlight"
    @switchDisplay="switchDisplay"
    @edit="showEdit"  
    @del="delPopup" 
    @add="unshiftArticle"
    @copy="handleCopy"
    @moveUp="handleMove('up')"
    @moveDown="handleMove('down')"
    @click.native="isShow = false">
      <div v-for="(item, ind) in list" :key="item.id" 
        :class="{
          'margin_bot16': item.display == 1 && currentForm[item.key_name],
        }" class="aloneCenter article_content">
        <div v-if="item.display == 1" class="flex prel">
          <div v-if="getProgressStatus(item)"
            class="progress-position"
            :class="{'hasValue': currentForm[item.key_name]}">
            <progressExample :progress="50" :detail="currentForm"></progressExample>
            <p v-html="currentForm[item.key_name]"
                class="progress-text"
                :class="{ 'fontsize_20': item.properties_name == 'title' || item.properties_name == 'displayTitle' }"></p>
          </div>
          <ckeditor 
            v-if="currentForm[item.key_name]"
            type="simpleInline" 
            :id="ckeditorPrefix+'-'+ detail.id +'-inline'+'-'+item.id"
            :canKeyEnter="canKeyEnter || item.properties_name !== 'displayTitle' && item.properties_name !== 'title'"
            :value="currentForm[item.key_name]" 
            :throttle="throttleDelay"
            @input="handleInput($event,item.key_name)"
            @blur="formItemBlur($event,item.key_name)"
            @blurNotUpdate="formItemBlur($event,item.key_name, false)"
            :class="{ 'is_title fontsize_16': (item.properties_name == 'title' || item.properties_name == 'displayTitle'),
            'fontsize_14 color_59':item.properties_name === 'body',
            'ckeditor_inline_instruction fontsize_14 color_8C':item.properties_name === 'instruction',
            'ck-has-progress': getProgressStatus(item),}" >
          </ckeditor>
        </div>
      </div>
      <transition-group name="blocks-complete" tag="div">
        <div v-for="(item, index) in blockList"
          :key="item.id" class="blocks-complete-item">
          <ToBlock 
            :ref="'block_'+item.id"
            :detail="getDetail(item)" 
            :containerStyle="containerStyle"
            :enabledProgress="enabledProgress"
            :moveUp="index !== 0"
            :moveDown="index !== (blockListLen - 1)"
            @blockSelectComponent="blockSelectComponent"
            @del="removeBlock" 
            @update="getBlocks" 
            @loaded="$emit('loaded')" 
            @scrollToNew="scrollToNew"
            @scrollToModule="scrollToModule">
          </ToBlock>
        </div>
      </transition-group>
      <!-- @mouseleave="isShow = false"  -->
      <div @click.stop="selectPosition" @mouseleave="isShow = false" 
        class="add-component-btn flex flex_acenter flex_center pointer prel"
        :class="{ 'not-highlight': blockActive }" >
          <i class="add-component-icon icon-add-component-green fontsize_24">
              <span class="path1"></span>
              <span class="path2"></span>
          </i>
          <span class="margin_left10 fontsize_14 color_222" :id="'addComp_'+detail.id">添加组件</span>
          <div class="select-component-position-wrapper" :class="{'isShow': isShow}">
            <div  class="select-component-position">
              <div class="popper-arrow"></div>
              <div class="select-component-position-inner">
                  <p class="fontsize_12 color_222 textcenter margin_bot3">选择结构</p>
                  <div class="flex flex_acenter">
                      <div class="structure_box" @click.stop="addBlock(1)">
                          <div class="structure_inner flex flex_acenter">
                              <div class="structure_item"></div>
                          </div>
                      </div>
                      <div class="structure_box" @click.stop="addBlock(2)">
                          <div class="structure_inner flex flex_acenter">
                              <div class="structure_item"></div>
                              <div class="structure_item"></div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </div>
      </div>
      <continueBtn v-if="detail['article-_trickle-properties-_isEnabled']" 
        :title="detail['article-_trickle-properties-_button-properties-text']"
      ></continueBtn>
    <fd-drawer
      destroy-on-close
      :title="drawerSet.title"
      :visible.sync="drawerSet.visible"
      :direction="drawerSet.direction"
      :before-close="handleClose"
      :size="479"
      custom-class="drawer-right not-header"
      append-to-body
      @open="openDrawer"
      @closed="closeDrawer"
      :modal="false"
      :with-header="false"
    >
      <div class="drawer-right-inner prel bgc_fff">
        <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
          <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
          <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="handleClose"></i>
        </div>
        <rightForm :ruleForm="detail" :tabList="tabList" :currentTab="drawerSet.activeName"
          :ckeditorNameId="'article-id'"
          :ckeditorPrefix="ckeditorPrefix"
          @handleClick="handleClick" @changeVal="changeVal" @formItemInput="formItemInput" 
          @selectChange="selectChange" @uploadImg="uploadImg" @formItemBlur="formItemBlur"
          @open="openInnerDrawer"
          class="drawer-right-inner-form">
        </rightForm>
        <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerDrawer(1)" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <scaffoldForm 
                v-if="innerDrawer.visible"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'article_ck'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
        </div>
      </div>
    </fd-drawer>
    <fd-dialog
      append-to-body
      :title="delDialogDetail.title"
      :visible.sync="delDialogDetail.visible"
      width="600px"
      :before-close="handleCloseDel"
      custom-class="course-dialog not-full">
      <p class="fontsize_16 color_222 textcenter margin_bot16" style="padding-top:9px;">请确认是否删除章节</p>
      <p class="fontsize_14 color_E74362 textcenter" style="padding-bottom:9px;">您确认要删除吗？删除之后将无法找回</p>
      <span slot="footer" class="dialog-footer">
        <button type="primary" @click="delArticle" class="course-save pointer">确定删除</button>
        <button @click="closeDelDialog" class="course-close pointer">不删了</button>
      </span>
    </fd-dialog>
  </topTools>
</template>

<script>
/**
 * 1、添加组件按钮 + 选择全宽/左右结构弹窗：
 * 1.1、点击-添加组件按钮，显示选择结构弹窗；
 * 1.2、鼠标移出除了按钮+弹窗之外，关闭弹窗；
*/
import Config from '@/libs/config';
import { mapState, mapMutations, mapActions } from 'vuex';
import {getBlockList} from '@/libs/api/block'
import topTools from '@/renderer/components/common/topTools.vue';
import ToBlock from './toblock.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
import progressExample from "@/renderer/components/common/progressExample.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import continueBtn from "@/renderer/components/common/continueBtn.vue";

export default {
  mixins: [hideModal],
  props:{
    detail: {},
    moveUp: Boolean,
    moveDown: Boolean,
    enabledProgress: Boolean,
    containerStyle: Object,
  },
  components:{ ToBlock, topTools, rightForm, progressExample, scaffoldForm, continueBtn },
  data() {
    return {
      componentName: "article",
      canKeyEnter:Config.titleCkeditorCanKeyEnter, //标题能否回车换行
      ckeditorPrefix:"article",
      isShow: false,
      demoBlock:{
        title: "组块标题",
        body: "",
        instruction:"",
        num: "1"
      },
      blockList:[],
      drawerSet: {
        direction: "rtl", //从右往左
        visible: false, //是否显示
        title: "",
        activeName: 0,
      },
      throttleDelay:Config.realTimeInput,
      currentForm: {},
      delDialogDetail: {
        title:"删除",
        visible: false,
        item:{}
      },
      leastOneCk: false,
      blockActive: false, //是否组块高亮
      innerDrawer: {
        visible: false, //是否显示
        title: "",
        form:{},
        propList:[],
        submitAsObj: false,
        key_name:"",
        current:-1,
      },
    };
  },
  computed: {
    ...mapState({
      popShowId: (state) => state.popShowId,
      schemaJson: state => state.course.schemaJson
    }),
    isHighlight() {
      // 当前编辑时-高亮
      return this.popShowId 
        && this.popShowId.includes(this.detail[ 'article-_id' ] + '_' + this.componentName)
        && this.popShowId[0] == this.detail[ 'article-_id' ] + '_' + this.componentName
        || false;
    },
    notHighlight() {
      // 非当前高亮-其他模块编辑
      return this.popShowId 
        && this.popShowId.includes(this.detail['article-_id'] + '_' + this.componentName)
        && this.popShowId[0] != (this.detail['article-_id'] + '_' + this.componentName) || false;
    },
    tabList() {
      return this.schemaJson[ this.componentName ] && this.schemaJson[ this.componentName ].tabgroup_list || []
    },
    list() {
      return (
        (this.tabList[0] &&
          this.tabList[0].setting_list.filter(v => (v.input_type === "richtext_mini" || v.input_type === "richtext") ) ) ||
        []
      );
    },
    styleProps() { //获取样式各项属性名
      let styleProps = {};
      this.tabList[1] &&  this.tabList[1].setting_list && this.tabList[1].setting_list.forEach(v=>{
        if(v.properties_name == "_backgroundStyles") {
          v.properties.forEach(sval=>{
            styleProps[sval.properties_name] = sval.key_name
          })
        }else {
          styleProps[v.properties_name] = v.properties[0].key_name
        }
      });
      return styleProps;
    },
    courseHeaderStyle() {
      return {
        'background-image': this.detail[this.styleProps['_backgroundImage']] ? `url(${this.detail[this.styleProps['_backgroundImage']]})` : 'none',
        'background-repeat': this.detail[this.styleProps['_backgroundRepeat']] || 'repeat',
        'background-position': this.detail[this.styleProps['_backgroundPosition']] || '0% 0%',
        'background-size': this.detail[this.styleProps['_backgroundSize']] || 'auto',
      }
    },
    blockListLen() {
      return this.blockList.length;
    }
  },
  mounted() {
    this.drawerSet.title = this.schemaJson['article'].title;
    this.getBlocks();
    this.currentForm = this.detail;
    this.getOneCk();
    this.$nextTick(() => {
      // console.log('article加载',this.detail['article-_id']);
      this.$emit("loaded");
    });
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    ...mapMutations(["openPop","SET_COURSE_FORM","closePop","del_article"]),
    ...mapActions(["SaveCourse","DelArticleOne","GetArticleList","CreateBlock",
      "GetBlockList","CreateArticle","DelBlockOne","MoveArticle",
      "CopyArticle"]),
    formChangeBlur(value,propName,leftForm) {
      let obj = {};
      obj[propName] = value;

      this.detail[propName] = value;
      this.$set(this.detail, propName, value);
      this.$forceUpdate();
      this.saveArticleDetail(obj, false);
    },
    openInnerDrawer({list, title, visible}) {
      // 显示\隐藏二层弹出窗口
      this.innerDrawer.title = title;
      this.innerDrawer.propList = list;

      if(!visible || visible == 2) {
        this.innerDrawer.visible = false;
        return;
      }else{
        this.innerDrawer.visible = true;
      }
    },
    closeInnerDrawer({list, title, visible} = {}) {
      // 显示\隐藏二层弹出窗口
      if(!visible) {
        this.innerDrawer.visible = false;
        return;
      }
    },
    handleCopy() {
      // 章节复制
      this.CopyArticle({
        course_id: this.detail.course_id,
        article_id: this.detail['article-_id'],
        _parentId: this.detail.contentobject_id,
      }).then(res => {
        if(res.errorcode == 0) {
        }
      });
    },
    handleMove(moveDirection) {
      // 章节上移/下移
      this.MoveArticle({
        course_id: this.detail.course_id,
        article_id: this.detail['article-_id'],
        _parentId: this.detail.contentobject_id,
        move_way: moveDirection
      }).then(res => {
        if(res.errorcode == 0) {
          // 拿到章节id，并让当前章节滑动到视野区域
          // this.$emit("scrollToNew",{id: res.data.article['article-_id']});
        }
      });
    },
    blockSelectComponent({addComponentVisible=false}) {
      // 选择组件时，添加组件按钮不高亮
      this.blockActive = !addComponentVisible;
    },
    getOneCk() { //至少一个输入框有值
      let target = this.list.findIndex( item => {
        return item.display == 1 && this.currentForm[item.key_name]
      } );
      // console.log('leastOneCk',target);
      this.leastOneCk =  target != -1;
    },
    getProgressStatus(item) {
      // 标题左侧 - 是否显示进度条
      if(!this.enabledProgress) return false;
      return this.currentForm['article-_pageLevelProgress-properties-_isCompletionIndicatorEnabled'] && (item.key_name === 'article-title' || item.key_name === 'article-displayTitle');
    },
    switchDisplay(isShow) {
      // 控制组块是否显示
      let obj = {};
      let prop = 'article-_isHidden';
      obj[prop] = !isShow;
      this.detail[prop] = !isShow;
      this.$forceUpdate(); //强制刷新
      this.saveArticleDetail(obj, false);
    },
    selectPosition() {
      this.isShow = true;
    },
    addBlock(num) {
      // 添加组件
      this.isShow = false;
      this.demoBlock.num = num;
      // this.$emit('addBlock',this.demoBlock)
      this.CreateBlock({
        course_id: this.detail.course_id,
        _parentId: this.detail['article-_id'],
        title: "",
        _layout: num==1?"full":"leftright"
      }).then(res=>{
        if(res.errorcode == 0) {
          let block_id = res.data.block['block-_id'];
          this.scrollToNew({id: block_id});
          // 新增block
          res.data.block.course_id = this.detail.course_id;
          res.data.block.article_id = this.detail['article-_id'];
          res.data.block.id = block_id;
          this.blockList.push( res.data.block );
          this.$nextTick( () => {
            let ele = this.$refs['block_' + block_id][0].$el.getElementsByClassName( 'select-component-btn' );
            //点击添加组件--选择结构，立马弹出组件选择框
            setTimeout(() => {
              ele && ele[0].click();
            }, 500);
          } );
          // this.GetBlockList({
          //   course_id: this.detail.course_id,
          //   article_id: this.detail['article-_id']
          // }).then(data=>{
          //   if(data.errorcode == 0) {
          //     this.blockList = data.data.block_list;
          //   }
          // }).catch();
        }
      }).catch();
    },
    getBlocks() {
      getBlockList(this.detail.course_id, this.detail['article-_id']).then(res=>{
        if(res.errorcode == 0) {
          this.blockList = res.data.block_list;
        }
      }).catch();
    },
    showEdit() {
      // 编辑章节
      let ids = [ this.detail['article-_id']+'_'+this.componentName ];
      this.openPop({type: this.componentName, id: this.detail['article-_id'], ids:ids });
      this.drawerSet.activeName = 0;
      this.drawerSet.visible = true;
    },
    unshiftArticle() {
      this.CreateArticle({
        course_id: this.detail.course_id,
        _parentId: this.detail.contentobject_id,
        title: "章节标题",
        below_article_id: this.detail['article-_id']
      }).then(res => {
        if(res.errorcode == 0) {
          // 拿到章节id，并让当前章节滑动到视野区域
          // res.data.article['article-_id']
          this.$emit("scrollToNew",{id: res.data.article['article-_id']});
        }
      })
    },
    handleCloseDel(done) {
      done();
      this.delDialogDetail.visible = false;
      this.delDialogDetail.item = {};
    },
    closeDelDialog() {
      this.delDialogDetail.visible = false;
      this.delDialogDetail.item = {};
    },
    delArticle() {
      this.DelArticleOne({
        course_id: this.detail.course_id,
        _id: this.detail.article_id,
        contentobject_id: this.detail.contentobject_id
      });
    },
    delPopup() {
      // 删除弹窗
      this.delDialogDetail.visible = true;
    },
    uploadImg(val,propName) {
      // console.log('uploadImg',val, propName)
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    changeVal(value, prop) {
      // console.log('changeVal',value,prop)
      // this.SET_COURSE_FORM(this.changeFormProp(value,prop));
      // this.detail[prop] = value;
      // this.$set(this.detail,prop,value);
      // this.$forceUpdate();
      // let obj = {};
      // obj[prop] = value;
      // this.saveArticleDetail(obj);
    },
    handleInput(value, prop) {
      //当前页面输入
      if( !!this.drawerSet.visible ) return;
      
      let obj = {};
      obj[prop] = value;
      
      this.$set(this.detail, prop, value); //左侧输入 同步到 右侧弹窗
      // this.$forceUpdate();  //导致新增章节，删除标题立即失去了光标
      this.saveArticleDetail(obj, false);
    },
    formItemInput(value, prop) { 
      //右侧弹窗输入
      let obj = {};
      obj[prop] = value;
      this.currentForm[prop] = value;
      this.getOneCk();
      this.$forceUpdate();
      this.saveArticleDetail(obj, false);
    },
    formItemBlur(value,propName, isUpdate = true) {
      let obj = {};
      obj[propName] = value;
      this.currentForm[propName] = value;
      this.detail[propName] = value;
      this.getOneCk();
      this.$forceUpdate();
      // if(this.currentForm[propName] === value) return; //switch切换被限制到
      !!isUpdate && this.saveArticleDetail(obj, true);
    },
    changeFormProp(prop,data) { //更新courseForm 某个属性
      let ruleForm  = JSON.parse(JSON.stringify(this.detail));
      ruleForm[prop] = data;
      return ruleForm;
    },
    selectChange(e,prop) {
      this.formItemBlur(e,prop);
    },
    handleClose(done) {
      //关闭右侧弹窗
      this.drawerSet.visible = false;
      this.closePop();
      typeof done === 'function' && done();
    },
    saveArticleDetail(updateObj, isUpdateList = false) {  //isUpdateList: 是否重新获取列表
      //保存章节详情
      this.SaveCourse({
        "page_key":"article","course_id":this.detail.course_id,
        "properties_data": updateObj,
        "article_id": this.detail['article-id']
      }).then(res=>{
        if(res.errorcode == 0) {
          if(isUpdateList) {
            this.GetArticleList({
              course_id: this.detail.course_id,
              contentobject_id: this.detail.contentobject_id
            });
          }
        }
      })
    },
    removeBlock({course_id, _id}) {
      this.DelBlockOne({course_id: course_id, _id: _id}).then(res => {
        if(res.errorcode == 0) {
          this.getBlocks();
        }
      })
    },
    getDetail(item) {
      item = JSON.parse( JSON.stringify( item ) )
      if(item.properties_list) {
        let result = Object.assign(item, item.properties_list);
        delete result.properties_list;
        return result;
      }
      return item;
    },
    scrollToNew({id}) {
      // console.log('block',id);
      this.$nextTick(() => {
        this.$emit("scrollToNew",{id});
      });
    },
    scrollToModule({id}) {
      this.$emit("scrollToModule",{id});
    },
  },
}
</script>

<style lang="less" scoped>
    //由于内部弹出窗口
    .drawer-right {
      /deep/ &.el-drawer {
        overflow: visible;
        .el-drawer__body {
          overflow: visible;
        }
      }
    }
    .is_hidden {
        opacity: 0;
    }
    .course-article {
        position: relative;
        box-sizing: border-box;
        padding: 1px 0; //撑开高亮边框
        &.hasValue {
          padding-top: 24px;
        }
    }
    .course-article:not(.highlight-block):not(.not-highlight):hover {
      //  &::after {
      //   content: "";
      //   display: block;
      //   width: 100%;
      //   height: 1px;
      //   background-color: #5ED8B8;
      //   position: absolute;
      //   border-bottom: 0.5;
      // }
      // 章节里面的“添加组件”按钮变绿
      // .add-component-btn:not(.not-highlight) {
      //   margin-inline: 1px;
      //   width: calc(100% - 2px);
      //   background-color: #51CFAE;
      // }
    }
    .add-component-btn {
        // margin: 24px 0 0;
        width: 100%;
        height: 48px;
        background-color: #EEEEEE;
        // “选择组件”按钮，鼠标移入
        &:hover {
          .add-component-icon .path1::before {
            color: #2A6958;
          }
        }
        &.not-highlight {
          //非高亮
          background-color: #EEEEEE;
        }
    }
    .select-component-position-wrapper {
      display: none;
      position: absolute;
      z-index: 1;
      bottom: 0;
      transform: translate(0, 100%);
      width: 100%;
      height: 134px;

      &.isShow {
          display: block;
      }
    }
    .select-component-position {
        // display: none;
        // position: absolute;
        // z-index: 1;
        // bottom: 0;
        // transform: translate(0, 100%);
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 330px;
        height: 134px;
        padding-top: 4px;

        // &.isShow {
        //     display: block;
        // }
    }
    .select-component-position-inner {
        box-sizing: border-box;
        padding: 13px 0 0;
        width: 100%;
        // height: 134px;
        box-shadow: 0 4px 12px 0 rgba(0,0,0,0.12);
        border-radius: 4px;
        border: 1px solid #E8EAE9;
        background: #FFFFFF;
    }
    .structure_box {
        box-sizing: border-box;
        flex: 1;
        padding: 19px 27px;
        height: 98px;
        &:hover {
            background-color: #F0F0F0;
        }
        .structure_inner {
            position: relative;
            box-sizing: border-box;
            border: 1px solid #222222;
            border-radius: 1px;
            padding: 0 9px;
            height: 60px;
        }
        .structure_item {
            width: 100%;
            box-sizing: border-box;
            border: 1px solid #5ED8B8;
            border-radius: 1px;
            height: 34px;
            background: #C3EFE3;
            &:nth-child(2) {
                margin-left: 10px;
            }
        }
    }

    .popper-arrow {
       top: -19px; 
    }

    .blocks-complete-item {
      transition: transform 0.4s, opacity 0.2s;
      width: 100%;
      // background-color: #fff; //防止在过渡时，由于透明且重叠，看到其他移动的内容
    }
    .blocks-complete-enter, .blocks-complete-leave-to
    /* .blocks-complete-leave-active for below version 2.1.8 */ {
      opacity: 0;
      transform: translateY(60px);
    }
    .blocks-complete-leave-active {
      position: absolute;
    }

    .article_content {
      box-sizing: border-box;
      padding: 0 16px 0;
    }
    .progress-position {
      left: 0;
      z-index: 0;
      padding-top: 2px;
      line-height: 1.3;
      display: inline-block;
      vertical-align: middle;
      &.hasValue {
        position: absolute;
      }
      .progress-text {
        width: 0;
        overflow: hidden;
        visibility: hidden;
        display: inline;
        vertical-align: middle;
        /deep/p {
          width: 0;
          overflow: hidden;
          display: inline;
          &:not(:first-child) {
            display: none;
          }
        }
      }
    }

    // 右侧弹窗头部高度
    @drawHeaderHeight: 54px;
    .drawer-right-inner {
      width: 100%;
      height: 100%;
      background-color: #fff;
      .header {
        box-sizing: border-box;
        height: @drawHeaderHeight;
      }
      .header-close-btn {
        position: absolute;
        top: 0;
        right: 0;
        padding: 19px 16px;
      }
      .drawer-right-inner-form {
        box-sizing: border-box;
        padding-top: 16px;
        height: calc(100% - @drawHeaderHeight);
      }
    }
</style>