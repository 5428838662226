// 颜色默认值
export const defaultColors = {
  white: "#FFF",
  blue: "#36CDE8",
  blueDark: "#263944",
  grey: "#9096A0",
  greyDark: "#4D4D4D",
  visited: "#727272",
  "visited-inverted": "#FFF",
  disabled: "#DDDDDD",
  "disabled-inverted": "#000",
  "validation-success": "#065f28",
  "validation-error": "#ff0000"
};
// 颜色值继承关系
export const defaultColorList = [
  {
    propName:"font-color",
    default:"greyDark", //默认颜色值-属性名
    from:"" //默认取值-属性名
  },
  {
    propName:"font-color-inverted",
    default:"white",
    from:""
  },
  {
    propName:"link",
    default:"",
    from:"font-color"
  },
  {
    propName:"link-inverted",
    default:"",
    from:"font-color-inverted"
  },
  {
    propName:"link-hover",
    default:"",
    from:"link"
  },
  {
    propName:"link-inverted-hover",
    default:"",
    from:"link-inverted"
  },
  {
    propName:"heading-color",
    default:"blue",
    from:""
  },
  {
    propName:"heading-color-inverted",
    default:"white",
    from:""
  },
  {
    propName:"item-color",
    default:"blue",
    from:""
  },
  {
    propName:"item-color-inverted",
    default:"white",
    from:""
  },
  {
    propName:"item-color-hover",
    default:"",
    from:"item-color", //darken(,10%)
    withMethod:true, //通过函数计算值
    params: {
      method:"darken",
      value: -10
    },
  },
  {
    propName:"item-color-inverted-hover",
    default:"",
    from:"item-color-inverted"
  },
  {
    propName:"item-color-selected",
    default:"",
    from:"item-color", //darken(,20%)
    withMethod:true, //通过函数计算值
    params: {
      method:"darken",
      value: -20
    },
  },
  {
    propName:"item-color-inverted-hover",
    default:"",
    from:"item-color-inverted"
  },
  {
    propName:"btn-color",
    default:"greyDark",
    from:""
  },
  {
    propName:"btn-color-inverted",
    default:"white",
    from:""
  },
  {
    propName:"btn-color-hover",
    default:"",
    from:"btn-color", //darken(,10%)
    withMethod:true, //通过函数计算值
    params: {
      method:"darken",
      value: -10
    },
  },
  {
    propName:"btn-color-inverted-hover",
    default:"",
    from:"btn-color-inverted"
  },
  {
    propName:"nav",
    default:"white",
    from:""
  },
  {
    propName:"nav-inverted",
    default:"grey",
    from:""
  },
  {
    propName:"nav-icon",
    default:"",
    from:"nav-inverted"
  },
  {
    propName:"nav-icon-inverted",
    default:"",
    from:"nav"
  },
  {
    propName:"nav-icon-hover",
    default:"",
    from:"nav-icon", //darken(,10%)
    withMethod:true, //通过函数计算值
    params: {
      method:"darken",
      value: -10
    },
  },
  {
    propName:"nav-icon-inverted-hover",
    default:"",
    from:"nav-icon-inverted"
  },
  {
    propName:"nav-progress",
    default:"",
    from:"nav-icon-inverted"
  },
  {
    propName:"nav-progress-inverted",
    default:"",
    from:"nav-icon"
  },
  {
    propName:"nav-progress-border",
    default:"",
    from:"nav-icon-inverted"
  },
  {
    propName:"nav-progress-hover",
    default:"",
    from:"nav-icon-inverted-hover"
  },
  {
    propName:"nav-progress-inverted-hover",
    default:"",
    from:"nav-progress-inverted"
  },
  {
    propName:"nav-progress-border-hover",
    default:"",
    from:"nav-progress-border"
  },
  {
    propName:"btn-icon-color",
    default:"",
    from:"nav-icon"
  },
  {
    propName:"btn-icon-color-inverted",
    default:"",
    from:"nav-icon-inverted"
  },
  {
    propName:"btn-icon-color-hover",
    default:"",
    from:"nav-icon-hover"
  },
  {
    propName:"btn-icon-color-inverted-hover",
    default:"",
    from:"nav-icon-inverted-hover"
  },
  {
    propName:"validation-success-inverted",
    default:"white",
    from:""
  },
  {
    propName:"validation-error-inverted",
    default:"white",
    from:""
  },
  {
    propName:"progress",
    default:"grey",
    from:""
  },
  {
    propName:"progress-inverted",
    default:"white",
    from:""
  },
  {
    propName:"progress-border",
    default:"grey",
    from:""
  },
  {
    propName:"menu-item",
    default:"white",
    from:""
  },
  {
    propName:"menu-item-inverted",
    default:"",
    from:"font-color"
  },
  {
    propName:"menu-item-border-color",
    default:"",
    from:"item-color"
  },
  {
    propName:"menu-item-progress",
    default:"",
    from:"progress"
  },
  {
    propName:"menu-item-progress-inverted",
    default:"",
    from:"progress-inverted"
  },
  {
    propName:"menu-item-progress-border",
    default:"",
    from:"progress-border"
  },
  {
    propName:"menu-item-btn-color",
    default:"",
    from:"btn-color"
  },
  {
    propName:"menu-item-btn-color-inverted",
    default:"",
    from:"btn-color-inverted"
  },
  {
    propName:"menu-item-btn-color-hover",
    default:"",
    from:"btn-color-hover"
  },
  {
    propName:"menu-item-btn-color-inverted-hover",
    default:"",
    from:"btn-color-inverted-hover"
  },
  {
    propName:"notify",
    default:"blue",
    from:""
  },
  {
    propName:"notify-inverted",
    default:"white",
    from:""
  },
  {
    propName:"notify-link",
    default:"",
    from:"notify-inverted"
  },
  {
    propName:"notify-link-hover",
    default:"",
    from:"notify-link"
  },
  {
    propName:"notify-btn",
    default:"",
    from:"btn-color-inverted"
  },
  {
    propName:"notify-btn-inverted",
    default:"",
    from:"btn-color"
  },
  {
    propName:"notify-btn-hover",
    default:"",
    from:"notify-btn", //darken(@notify-btn, 10%)
    withMethod:true, //通过函数计算值
    params: {
      method:"darken",
      value: -10
    },
  },
  {
    propName:"notify-btn-inverted-hover",
    default:"",
    from:"notify-btn-inverted"
  },
  {
    propName:"notify-icon",
    default:"",
    from:"notify"
  },
  {
    propName:"notify-icon-inverted",
    default:"",
    from:"notify-inverted"
  },
  {
    propName:"notify-icon-hover",
    default:"",
    from:"notify-icon", //darken(@notify-icon, 10%)
    withMethod:true, //通过函数计算值
    params: {
      method:"darken",
      value: -10
    },
  },
  {
    propName:"notify-icon-inverted-hover",
    default:"",
    from:"notify-icon-inverted"
  }
];

export const LightenDarkenColor = function(col, amt, type) {
  return changeColor(col, amt, type);
}

function toHSL(r, g, b) {
  r /= 255, g /= 255, b /= 255;
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h, s, l = (max + min) / 2;

  if (max == min) {
    h = s = 0; 
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return {
    h,
    s,
    l
  };
}

function hslToRgb(h, s, l) {
  var r, g, b;

  if (s == 0) {
    r = g = b = l;
  } else {
    var hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    }

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function rgbToHex(rgbArr) {
  var hex = '';
  rgbArr.forEach(rgb => {
    var result = Number(rgb).toString(16);
    if (result.length === 1) {
      result = result.toString().padStart(2, "0");
    }
    hex += result.toLocaleLowerCase();
  })
  return hex;
}
function clamp(val) {
  return Math.min(1, Math.max(0, val));
}

function changeColor(color, amount, type) {
  // hex ->  rgb -> hsl -> 计算l -> rgb -> hex
  var newcolor = rgbOrRgbaToHex(color); //解决传入 rgba|rgb 报错问题
  var hsl = toHSL(...hexToRGB(newcolor));
  if (type === 'darken') {
    hsl.l -= amount / 100;
  } else if (type === 'lighten') {
    hsl.l += amount / 100;
  }
  hsl.l = clamp(hsl.l);
  
  return '#' + rgbToHex(hslToRgb(hsl.h, hsl.s, hsl.l));
}

function hexToRGB(hex) {
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  var sColor = hex.toLowerCase();
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      var sColorNew = "#";
      for (var i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1));
      }
      sColor = sColorNew;
    }
    var sColorChange = [];
    for (var i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt("0x" + sColor.slice(i, i + 2)));
    }
    return sColorChange;
  }
  
  return '#ff0000'
}

function rgbOrRgbaToHex(value) {
  if (/rgba?/.test(value)) {
      var array = value.split(",");
      //不符合rgb或rgb规则直接return
      if (array.length < 3)
          return "";
      value = "#";
      for (var i = 0, color; color = array[i++];) {
        if(i < 4){
          //前三位转换成16进制
          color = parseInt(color.replace(/[^\d]/gi, ''), 10).toString(16);
          value += color.length == 1 ? "0" + color : color;
        }else{
          //rgba的透明度转换成16进制
          color = color.replace(')', '')
          var colorA = parseInt(color*255);
        var colorAHex = colorA.toString(16);
        value += colorAHex;
        }
      }
      value = value.toUpperCase();
  }
  return  value;
}